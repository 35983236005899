import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import InputField from '../elements/input';
import SelectField from '../elements/select';
import TextAreaField from '../elements/textarea';
import CheckboxField from '../elements/checkbox';
import ButtonField from '../elements/button';
import HtmlBox from '../elements/html';
import ReCaptchaBox from '../elements/recaptcha';
import '../assets/styles/_index.scss';
import  "./assets/_index.scss";
import { postFormData } from "../api/Api"

import axios from "axios";
import * as qs from "query-string";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

function SubscribeForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "Subscribe to latest News",
        error_text: "Highlighted fields are required",
        success_text: "Thank you for subscribing to our newsletter. We will be in touch.",
        email_temp_user: "subscribe_form_user",
        email_temp_admin: "subscribe_form_admin",
        email_server_func: "subscribe-form",
        event_tracking: "subscribe-form",
        page_url: "/"
      },
      {
        grpmd: "4",
        groupClass: "mb-15 mb-md-34 col-xl-3 col-md-4 col-12 offset-xl-2",
        label: "",
        placeholder: "Name",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        class: ""
      },
      {
        grpmd: "4",
        groupClass: "mb-15 mb-md-34 col-xl-3 col-md-4 col-12",
        label: "",
        placeholder: "Email Address",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        class: ""
      },
      {
        name: "Subscribe",
        type:"submit",
        element: "button",
        value: "submit",
        groupClass: "mb-15 mb-md-34 text-md-left col-xl-2 col-md-4 col-12",
        class: "btn-block btn btn-primary"
      },
      {
        text: '<p>By clicking enquire I agree to the Crux Careers <a href="/terms-and-conditions/" className="content-link">Terms & Conditions</a></p>',
        element: "html",
        class: "form-subscribe__link col-12"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

        formvalues['g-recaptcha-response'] = token;

        let formData = new FormData();

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(apiRes => {

          // lets send mail
          const axiosOptions_email = {
            url: '/.netlify/functions/server/'+fields[0].email_server_func,
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(formvalues),
          }

          axios(axiosOptions_email)
            .then(response => {
              console.log('mail sent!')
            })
            .catch(err =>
              console.log(err)
            );
          });

        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-'+fields[0].event_tracking,
          'formId': 'form-'+fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;

      json['g-recaptcha-response'] = token;

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      
    }
  };

    let contAnim = useRef(null);
    let titleAnim = useRef(null);
    let form = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.to(contAnim, {
            duration: 0,
            css: {visibility: 'visible'}
        })
        .from([titleAnim, form], {
            opacity: 0,
            y: 70,
            duration: 1.5,
            stagger: 0.6
        })
    }, [])

    return (
        <section className={`section section-subscribe-form${props.sectionClass}`} ref={el => contAnim = el}>
          <Container>
              <Row>
                  <Col xs={12}>
                      <h2 className="section-title h5 mb-21 mb-md-35 mb-xl-47" ref={el => titleAnim = el}>{props.Title}</h2>
                          <div className="form stbform">
                          <div ref={myRef} />

                          {showerror && <div className="alert-error">
                          <p>{fields[0].error_text}</p>
                          </div>}

                          {showthankyou && <div className="alert-success">
                          <p>{fields[0].success_text}</p>
                          </div>}

                          
                          <Form name={fields[0].formname} action="/thank-you/" className={props.formClass} method="post" noValidate validated={validated} onSubmit={handleSubmit} data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true" ref={el => form = el}>
                              <input type="hidden" name="form_name" value={fields[0].formname} />
                              <input type="hidden" name="bot-field" />
                              <Form.Row className="align-items-center">
                              {fields.map((field, index) => {
                                  if ( "input" === field.element ) {
                                    return (
                                        <InputField
                                        name={field.name}
                                        grpmd={field.grpmd}
                                        groupClass={field.groupClass}
                                        label={field.label}
                                        ref={field.ref}
                                        type={field.type}
                                        nolabel={true}
                                        fieldClass={field.class}
                                        placeholder={field.placeholder+'*'}
                                        required={field.required}
                                        key={`${field.element}~${index}`}
                                        pattern={field.patternchk}
                                        handlechange={handlechange}
                                        />
                                    );
                                  }
                                  if ("select" === field.element) {
                                      return (
                                          <SelectField
                                          name={field.name}
                                          grpmd={field.grpmd}
                                          groupClass={field.groupClass}
                                          label={field.label}
                                          ref={field.ref}
                                          required={field.required}
                                          fieldClass={field.class}
                                          placeholder={field.placeholder}
                                          values={field.values}
                                          key={`${field.element}~${index}`}
                                          handlechange={handlechange}
                                          componentprops={props}
                                          />
                                      );
                                  }
                                  if ("textarea" === field.element) {
                                      return (
                                          <TextAreaField
                                          name={field.name}
                                          grpmd={field.grpmd}
                                          groupClass={field.groupClass}
                                          label={field.label}
                                          ref={field.ref}
                                          rows={field.rows}
                                          fieldClass={field.class}
                                          placeholder={field.placeholder}
                                          required={field.required}
                                          key={`${field.element}~${index}`}
                                          handlechange={handlechange}
                                          />
                                      );
                                  }
                                  if ("checkbox" === field.element) {
                                      return (
                                          <CheckboxField
                                          name={field.name}
                                          ref={field.ref}
                                          value={field.value}
                                          fieldClass={field.class}
                                          placeholder={field.placeholder}
                                          required={field.required}
                                          key={`${field.name}~${index}`}
                                          handlechange={handlechange}
                                          />
                                      );
                                  }
                                  if ("html" === field.element) {
                                      return (
                                          <HtmlBox
                                          text={field.text}
                                          fieldClass={field.class}
                                          key={`${field.element}~${index}`}
                                          />
                                      );
                                  }
                                  if ("captcha" === field.element) {
                                    return (
                                      <ReCaptchaBox
                                        fieldClass={field.class}
                                        captRef={field.captchaRef}
                                        key={`${field.element}~${index}`}
                                        handleonVerify={handleonVerify}
                                      />
                                    );
                                  }
                                  if ("button" === field.element) {
                                      return (
                                          <ButtonField
                                          name={field.name}
                                          fieldClass={field.class}
                                          type={field.type}
                                          value={field.value}
                                          key={`${field.element}~${index}`}
                                          groupClass={field.groupClass}
                                          />
                                      );
                                  }
                              })
                              }
                              </Form.Row>
                          </Form>
                          </div>
                      </Col>
                  </Row>
              </Container>
        </section>
    );
}


const SubscribeFormModule = (props) => (
  <SubscribeForm
    subject={props.subject}
    sectionClass={props.sectionClass}
    formClass={props.formClass}
    Title={props.Title}
  />
)

// Specifies the default values for props:
SubscribeFormModule.defaultProps = {
    sectionClass: ' section-primary-linear-gradient text-center pt-33 pb-36 pt-md-52 pb-md-56 pt-xl-132 pb-xl-139',
    formClass: 'form-subscribe',
    Title: 'Get the latest Estate Agency jobs, candidates and other industry news straight to your inbox.'
};

export default SubscribeFormModule